<template>
  <div class="event-statistics-page">
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">Cargando...</h1>
        </div>
      </template>
    </b-loading>
    <section v-if="statistics && !is_loading" class="event-detail-page">
      <div class="event-header">
        <div class="container">
          <div class="columns is-vcentered">
            <div class="column is-8">
              <div class="event-info">
                <h2 class="title">{{ statistics.nombre }}</h2>
                <h3 class="teather has-text-link">{{ statistics.teatro }}</h3>
                <div class="columns is-multiline">
                  <div class="column is-6">
                    <div class="info-label">Categoria</div>
                    <div class="info-value">{{ statistics.categoria }}</div>
                  </div>
                  <div class="column is-6">
                    <div class="info-label">Presenta</div>
                    <div class="info-value">{{ statistics.presenta }}</div>
                  </div>
                  <div class="column is-6">
                    <div class="info-label">Duración aprox</div>
                    <div class="info-value">{{ statistics.duracion }}</div>
                  </div>
                  <div class="column is-6">
                    <div class="info-label">Dirección</div>
                    <div class="info-value">{{ statistics.direccion }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="statistics.autonomo == 1" class="column is-4">
              <div class="event-trailer">
                <h2 class="title">Guías</h2>
                <iframe src="https://www.youtube.com/embed/videoseries?si=QHNr2Gu_cOGVIz5h&amp;list=PL0TrKa2zLPOaqXqv-0RRHok6M1haBnjR6" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="event-statistics">
        <div class="container">
          <div class="columns is-multiline">
            <div v-for="fecha in statistics.fechas" :key="fecha.id" class="column is-12">
              <div v-for="funcion in fecha.funciones" :key="funcion.id" class="statistics-table">
                <div class="columns is-vcentered">
                  <div class="column is-6">
                    <h2>Fecha: {{ fecha.fecha }}</h2>
                    <h3>Función: {{ funcion.hora }} H</h3>
                  </div>
                  <div class="column is-6 has-text-right">
                    <router-link
                      :to="{ name: 'check-in-tickets', params: { code: $route.params.code, funcion: funcion.id } }"
                      v-if="statistics.autonomo == 1"
                    >
                      <b-button class="vat-btn-purple has-text-white" type="is-warning" rounded> Escanear Función </b-button>
                    </router-link>
                  </div>
                </div>

                <b-table :data="funcion.boletos" :mobile-cards="false">
                  <b-table-column field="ticket" label="Tipo de boleto" v-slot="props">
                    {{ props.row.nombre }}
                  </b-table-column>
                  <b-table-column field="vendidos" label="Vendidos" v-slot="props">
                    {{ props.row.vendidos }}
                  </b-table-column>
                </b-table>
                <div v-if="statistics.autonomo == 1" class="statistics-orders">
                  <div class="columns is-vcentered">
                    <div class="column is-6">
                      <h2>Ordenes</h2>
                    </div>
                  </div>
                  <b-table
                    :data="funcion.ordenes"
                    :mobile-cards="false"
                    paginated
                    :per-page="50"
                    aria-next-label="Siguiente"
                    aria-previous-label="Anterior"
                    aria-page-label="Pagina"
                    aria-current-label="Pagina actual"
                  >
                    <b-table-column field="ticket" label="Orden" sortable searchable v-slot="props">
                      {{ props.row.ticket }}
                    </b-table-column>
                    <b-table-column field="comprador" label="Comprador" searchable sortable v-slot="props">
                      {{ props.row.comprador }}
                    </b-table-column>
                    <b-table-column field="correo" label="Correo" searchable sortable v-slot="props">
                      {{ props.row.correo }}
                    </b-table-column>
                    <b-table-column field="codigo_boleto" label="Código Boleto" searchable sortable v-slot="props">
                      {{ props.row.codigo_boleto }}
                    </b-table-column>
                    <b-table-column field="tipo_boleto" label="Tipo boleto" searchable sortable v-slot="props">
                      {{ props.row.tipo_boleto }}
                    </b-table-column>
                    <b-table-column field="fila_columna" label="Asiento" searchable sortable v-slot="props">
                      {{ props.row.fila !== null ? props.row.fila + ' - ' + props.row.columna : '' }}
                    </b-table-column>
                    <b-table-column field="estatus" label="Estatus" v-slot="props">
                      <b-tag v-if="props.row.estatus == 'INGRESADO'" type="is-success" rounded>INGRESADO</b-tag>
                      <b-tag v-else type="is-danger" rounded>NO INGRESADO</b-tag>
                    </b-table-column>
                    <b-table-column field="acciones" label="" v-slot="props">
                      <b-button @click="updateTickets(props.row)" type="is-info" rounded>Cambiar Estatus</b-button>
                    </b-table-column>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="column is-12 download-report">
              <b-button
                v-if="statistics.autonomo == 1"
                class="vat-btn-purple has-text-white"
                tag="a"
                :href="'https://voyalteatro.com/backend/estadisticas/assistancetaq/' + statistics.id"
                target="_blank"
                type="is-warning"
                rounded
                >Descargar Reporte de ventas</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Estadisticas',
  data() {
    return {
      is_loading: true,
    };
  },
  computed: {
    ...mapState('event', ['statistics']),
  },
  methods: {
    ...mapActions('event', ['fetchStatistics', 'updateTicketsFromQr']),
    async loadStatistics() {
      await this.fetchStatistics({ code: this.$route.params.code });
      this.is_loading = false;
    },
    async updateTickets(ticket) {
      const boletos_update = [
        {
          id: ticket.ticket,
          estatus: ticket.estatus == 'NO_INGRESADO' ? 'INGRESADO' : 'NO_INGRESADO',
        },
      ];

      const response = await this.updateTicketsFromQr({ boletos: boletos_update });

      if (response) {
        await this.loadStatistics();
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadStatistics();
    });
  },
};
</script>