<template>
  <div class="blog-list-page">
    <div class="modal" :class="{ 'is-active': blogModalVideo }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <b-button @click="closeModal" icon-left="close"></b-button>
        </header>
        <section class="modal-card-body">
          <iframe :src="blogModalVideoUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
        </section>
      </div>
    </div>

    <div class="container">
      <h2 class="title">Blog</h2>
      <div class="search-blogs">
        <div class="columns is-mobile is-centered">
          <div class="column is-half-desktop is-full-mobile is-full-tablet">
            <div class="search">
              <SearchAutocomplete
                :entityType="entityType"
                :onTyping="handleTyping"
                locationTitle="Descripcion"
                :onSelect="handleSelect"
                :data="searchList"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="columns is-multiline">
          <div v-for="blog in list" :key="blog.id" class="column is-4">
            <router-link
              v-if="blog.post_video == 0"
              class="blog-list-card"
              :to="getLink(blog)"
              :style="{ backgroundImage: `url(${blog.portada + '/500/500' || 'https://voyalteatro.mx/empty.png'})` }"
            >
              <div class="card-blog-details">
                <p class="card-blog-name clamp-3">{{ blog.titulo }}</p>
                <p class="card-blog-date">{{ formatDate(blog.fecha) }}</p>
              </div>
              <div class="card-image">
                <img :src="'https://voyalteatro.mx/fondo_blanco_500x600.png'" alt="" />
              </div>
            </router-link>

            <div
              v-else
              class="blog-list-card"
              :style="{ backgroundImage: `url(${blog.portada + '/500/500' || 'https://voyalteatro.mx/empty.png'})` }"
              @click="openModal(blog)"
            >
              <div class="card-blog-details">
                <p class="card-blog-name clamp-3">{{ blog.titulo }}</p>
                <p class="card-blog-date">{{ formatDate(blog.fecha) }}</p>
              </div>
              <div class="card-image">
                <img :src="'https://voyalteatro.mx/fondo_blanco_500x600.png'" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="totalPages > currentPage" class="columns is-mobile is-centered load-more">
          <div class="column is-12 is-half has-text-centered">
            <b-button @click="loadMore" class="is-medium vat-btn" type="is-info" icon-right="menu-down">{{
              translatedText('load_more_publications')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <section class="google-adds">
      <GoogleAdds :key="$route.fullPath" />
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState, mapGetters } from 'vuex';
import SearchAutocomplete from '@/components/Common/autocomplete.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  name: 'Blog',
  components: {
    SearchAutocomplete,
    GoogleAdds,
  },
  data() {
    return {
      entityType: 'publicacion',
      blogModalVideo: false,
      blogModalVideoUrl: null,
    };
  },
  computed: {
    ...mapState('blogs', ['list', 'options']),
    ...mapGetters('blogs', ['searchList']),
    totalPages() {
      return this.options.totalPages || 1;
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadQueryParams();
    });
  },
  mounted() {
    this.$store.commit('blogs/INIT_STATE');
    window.scrollTo(0, 0);
    this.loadBlogsList();
  },
  methods: {
    ...mapActions('blogs', ['fetchBlogsByCategories', 'fetchSearchList']),
    loadBlogsList() {
      const { page, size } = this.$route.query;

      const newQuery = {
        page,
        size,
        categorias_id: this.$route.params.id,
      };

      this.fetchBlogsByCategories(newQuery);
    },
    loadQueryParams() {
      this.$store.commit('ui/SET_LOADING', true);

      const currentPage = 1;
      const currentSize = 9;

      const newQuery = {
        page: currentPage,
        size: currentSize,
      };

      this.$store.commit('ui/SET_LOADING', false);

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });
    },
    loadMore() {
      const { page } = this.$route.query;
      const nextPage = parseInt(page) + 1;
      const newQuery = { ...this.$route.query, page: nextPage };

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });

      this.loadBlogsList(newQuery);
    },
    handleTyping(value) {
      const trimmedValue = value.trim();
      const lengthWithoutSpaces = trimmedValue.length;

      if (lengthWithoutSpaces >= 5) {
        const newQuery = {
          searchValue: trimmedValue,
          categorias_id: this.$route.params.id,
        };

        this.fetchSearchList(newQuery);
      }
    },
    handleSelect(option) {
      if (option && option.id) {
        this.$router.push({ name: 'blog-detail', params: { id: option.id } });
      }
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      moment.locale('es');
      let fechaFormateada = moment(date).format('DD [de] MMMM [de] YYYY');
      fechaFormateada = `${fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)}`;
      return fechaFormateada;
    },
    getLink(blog) {
      if (blog.post_video == 1) {
        return '#';
      } else {
        return { name: 'blog-detail', params: { id: blog.id } };
      }
    },
    openModal(blog) {
      if (blog.post_video == 1) {
        this.blogModalVideoUrl = blog.video_portada;
        this.blogModalVideo = true;
      }
    },
    closeModal() {
      this.blogModalVideo = false;
      this.blogModalVideoUrl = null;
    },
  },
};
</script>
