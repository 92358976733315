<template>
  <div class="billboard-page">
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">{{ translatedText('loading') }}</h1>
        </div>
      </template>
    </b-loading>
    <div class="container">
      <h2 class="title">{{ translatedText('showtimes_2') }}</h2>

      <div class="search-filters">
        <div class="columns is-multiline is-mobile">
          <div class="column is-half-desktop is-full-mobile is-full-tablet">
            <div class="search">
              <SearchAutocomplete
                :labelText="translatedText('search_events')"
                :entityType="entityType"
                :onTyping="handleTyping"
                :onSelect="handleSelect"
                :data="searchList"
              />
            </div>
          </div>
          <div class="column is-half-desktop is-full-mobile is-full-tablet">
            <div class="calendar">
              <label class="label">{{ translatedText('search_by_date') }}</label>
              <Datepicker :date="activeDate" :events="eventDates" @date-selected="handleDateSelected" />
            </div>
          </div>
        </div>
      </div>
      <div class="filters">
        <div v-if="filters.length > 0" class="columns is-multiline is-mobile">
          <div class="column is-12">
            <div class="buttons is-justify-content-center">
              <b-button @click="handleFilterClick('all')" class="vat-btn" type="is-info" :class="{ 'is-active': 'all' === activeFilter }">
                {{ translatedText('all_events') }}
              </b-button>
              <b-button
                v-for="filter in filters"
                :key="filter.id"
                @click="handleFilterClick(filter)"
                class="vat-btn"
                type="is-info"
                :class="{ 'is-active': filter.url === activeFilter }"
              >
                {{ filter.nombre }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="columns is-multiline is-mobile">
          <div v-for="event in list" :key="event.id" class="column is-one-third-desktop is-half-tablet is-full-mobile">
            <EventCard :event="event" :show-details="true" />
          </div>
        </div>
        <NotFound v-if="!is_loading && !list.length > 0" :title="this.translatedText('no_events_title_message')" :subtitle="' '" :show-url="false" />
      </div>
      <div v-if="totalPages > currentPage" class="columns is-mobile is-centered load-more">
        <div class="column is-half-desktop is-full-mobile is-full-tablet has-text-centered">
          <b-button @click="loadMore" class="is-medium vat-btn" type="is-info" icon-right="menu-down">{{
            translatedText('load_more_events')
          }}</b-button>
        </div>
      </div>
      <section class="google-adds">
        <GoogleAdds :key="$route.fullPath" />
      </section>
      <section class="section caption-text">
        <h2 class="title">{{ translatedText('contact_us_event_message') }}</h2>
      </section>
    </div>
  </div>
</template>

<script>
import Datepicker from '@/components/Common/datepicker.vue';
import SearchAutocomplete from '@/components/Common/autocomplete.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';
import EventCard from './event-card.vue';
import NotFound from '../404.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Billboard',
  components: {
    Datepicker,
    SearchAutocomplete,
    EventCard,
    GoogleAdds,
    NotFound,
  },
  data() {
    return {
      entityType: 'eventos',
      is_loading: true,
      activeFilter: 'all',
      activeDate: null,
    };
  },
  computed: {
    ...mapState('billboard', ['list', 'options', 'filters', 'eventDates']),
    ...mapGetters('billboard', ['searchList']),
    totalPages() {
      return this.options.totalPages || 1;
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadQueryParams();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === 'cartelera') {
      this.$store.commit('billboard/INIT_STATE');
    }
    next();
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadBillboard();
  },
  methods: {
    ...mapActions('billboard', ['fetchBilboard', 'fetchSearchList']),
    handleTyping(value) {
      const trimmedValue = value.trim();
      const lengthWithoutSpaces = trimmedValue.length;

      if (lengthWithoutSpaces >= 5) {
        const newQuery = { searchValue: trimmedValue };
        this.fetchSearchList(newQuery);
      }
    },
    handleSelect(option) {
      if (option && option.id) {
        this.$router.push({ name: 'evento-detail', params: { id: option.id } });
      }
    },
    loadQueryParams() {
      this.$store.commit('ui/SET_LOADING', true);
      const { filter, date } = this.$route.query;

      const currentPage = 1;
      const currentSize = 21;
      const currentFilter = filter || null;
      const currentDate = date || null;

      const newQuery = {
        page: currentPage,
        size: currentSize,
      };

      if (currentFilter) {
        newQuery.filter = currentFilter;
        this.activeFilter = currentFilter;
      }

      if (currentDate) {
        newQuery.date = currentDate;
      }

      this.$store.commit('ui/SET_LOADING', false);

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });
    },
    async loadBillboard() {
      const { page, size, filter, date } = this.$route.query;

      const newQuery = {
        page,
        size,
      };

      if (filter) {
        newQuery.filter = filter;
      }

      if (date) {
        newQuery.date = date;
      }

      await this.fetchBilboard(newQuery);

      this.is_loading = false;
    },
    loadMore() {
      const { page } = this.$route.query;
      const nextPage = parseInt(page) + 1;
      const newQuery = { ...this.$route.query, page: nextPage };

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });

      this.fetchBilboard(newQuery);
    },
    handleFilterClick(selectedFilter) {
      let newQuery = { ...this.$route.query, page: 1 };

      if (selectedFilter == 'all') {
        delete newQuery.filter;
        delete newQuery.date;
        this.clearDate();
        this.activeFilter = selectedFilter;
      } else {
        newQuery.filter = selectedFilter.url;
      }

      this.updateRoute(newQuery);

      if (selectedFilter != 'all') {
        this.activeFilter = selectedFilter.url;
      }
    },
    handleDateSelected(date) {
      if (date == null) {
        this.clearDate();
      } else {
        const formatDate = moment(new Date(date)).format('YYYY-MM-DD');
        const newQuery = { ...this.$route.query, page: 1, date: formatDate };

        this.activeDate = date;

        this.updateRoute(newQuery);
      }
    },
    clearDate() {
      this.activeDate = null;

      const { page, size, filter } = this.$route.query;

      const newQuery = {
        page,
        size,
      };

      if (filter) {
        newQuery.filter = filter;
      }

      this.updateRoute(newQuery);
    },
    async updateRoute(newQuery) {
      this.$store.commit('ui/SET_LOADING', false);

      await this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });

      this.fetchBilboard(newQuery);
    },
  },
};
</script>