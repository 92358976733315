<template>
  <div class="home-page">
    <div v-if="banners.length > 0" class="home-carousel">
      <div class="container">
        <div class="columns">
          <div class="column">
            <Carousel :items="banners" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="featuredEvents.length > 0" class="home-featured-events">
      <div class="container">
        <h2 class="title">{{ translatedText('featured_events') }}</h2>
        <Carousel-list :items="featuredEvents" />
      </div>
    </div>
    <section class="google-adds">
      <GoogleAdds :key="$route.fullPath" />
    </section>
    <section class="frequent-spectator" v-if="sectionTop && sectionTop.fondo" :style="{ 'background-image': 'url(' + sectionTop.fondo + ')' }">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <b-image :src="sectionTop.imagen" alt="" />
          </div>
          <div class="column is-half">
            <div class="frequent-spectator-content" v-html="sectionTop.contenido"></div>
            <b-button class="vat-btn is-medium" tag="a" :href="sectionTop.url_boton" type="is-info" expanded>{{ sectionTop.texto_boton }}</b-button>
          </div>
        </div>
      </div>
    </section>
    <section v-if="sectionBottom" class="surveys" style="background: linear-gradient(#73eafb, #0ca1fe)">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <b-image :src="sectionBottom.imagen" alt="" />
          </div>
          <div class="column is-half">
            <div class="surveys-content" v-html="sectionBottom.contenido"></div>
            <b-button class="vat-btn is-medium" tag="a" :href="sectionBottom.url_boton" type="is-info" expanded>{{
              sectionBottom.texto_boton
            }}</b-button>
          </div>
        </div>
      </div>
    </section>
    <section v-if="blog.length > 0" class="latest-blog-posts">
      <div class="container">
        <h2 class="title">{{ translatedText('newest_in_blog') }}</h2>
        <div class="latest-blog-posts-container">
          <div v-for="(itemBlog, b) in blog" :key="b" class="card">
            <div class="card-image">
              <figure class="image">
                <router-link :to="{ name: 'blog-detail', params: { id: itemBlog.id } }" :title="itemBlog.title">
                  <img :src="itemBlog.image" :alt="itemBlog.title" />
                  <p class="clamp-3">{{ itemBlog.title }}</p>
                </router-link>
              </figure>
            </div>
          </div>
          <div class="card-btn">
            <b-button class="vat-btn is-medium is-pulled-right" tag="router-link" :to="{ name: 'blog' }" type="is-info">{{
              translatedText('see_more_entries')
            }}</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Carousel from '@/components/Common/carousel';
import CarouselList from '@/components/Common/carousel-list';
import GoogleAdds from '@/components/Common/google-adds.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Root',
  components: {
    Carousel,
    CarouselList,
    GoogleAdds,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('dashboard', ['banners', 'featuredEvents', 'blog', 'sectionTop', 'sectionBottom']),
    testas() {
      return [this.banners[2]];
    },
  },
};
</script>