<template>
  <div class="theater-page">
    <div class="theater-header">
      <h2 class="title">{{ translatedText('theaters_page') }}</h2>
    </div>

    <div class="filters">
      <div v-if="filters.length > 0" class="columns is-multiline is-mobile">
        <div class="column is-12">
          <div class="buttons is-justify-content-center">
            <b-button @click="handleFilterClick('all')" class="vat-btn" type="is-info" :class="{ 'is-active': 'all' === activeFilter }">
              {{ translatedText('all_theaters') }}
            </b-button>
            <b-button
              v-for="filter in filters"
              :key="filter.id"
              @click="handleFilterClick(filter)"
              class="vat-btn"
              type="is-info"
              :class="{ 'is-active': filter.url === activeFilter }"
            >
              {{ filter.nombre }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="list.length" class="theater-list">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-4" v-for="theater in limitedList" :key="theater.id">
            <router-link :to="{ name: 'teatro-detail', params: { id: theater.id } }">
              <div
                class="card theater-card"
                :style="{
                  backgroundImage: `url(${
                    theater.portada ? theater.portada + '/600/300' : 'https://voyalteatro.com/backend/api/img/render/9339/600/300'
                  })`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }"
                @error="theater.error = true"
              >
                <p class="theater-status">{{ theater.nombre }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns is-mobile is-centered load-more" v-if="visibleTheaters < list.length">
          <div class="column is-half-desktop is-full-mobile is-full-tablet has-text-centered">
            <b-button class="is-medium vat-btn" type="is-info" icon-right="menu-down" @click="loadMoreTheaters">
              {{ translatedText('load_more_theaters') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <section class="google-adds">
      <GoogleAdds :key="$route.fullPath" />
    </section>
    <section class="section caption-text">
      <h2 class="title">{{ translatedText('contact_us_teather_message') }}</h2>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  name: 'TheaterList',
  components: {
    GoogleAdds,
  },
  data() {
    return {
      visibleTheaters: 21, // Inicialmente, muestra los primeros 20 teatros
      limit: 21, // Cantidad de teatros a cargar por vez
      activeFilter: 'all',
      is_loading: true,
    };
  },
  computed: {
    ...mapState('theaters', ['list', 'filters']),
    limitedList() {
      return this.list.slice(0, this.visibleTheaters);
    },
  },
  methods: {
    ...mapActions('theaters', ['fetchTheaters']),
    loadMoreTheaters() {
      this.visibleTheaters += this.limit; // Incrementa la cantidad visible
    },
    loadTheaters() {
      const { filter } = this.$route.query;

      const newQuery = {};

      if (filter) {
        newQuery.filter = filter;
      }

      this.$store.commit('ui/SET_LOADING', true);
      this.fetchTheaters(newQuery);
      this.visibleTheaters = 21;
      this.$store.commit('ui/SET_LOADING', false);
    },
    loadQueryParams() {
      this.$store.commit('ui/SET_LOADING', true);
      const { filter } = this.$route.query;
      const currentFilter = filter || null;

      const newQuery = {};

      if (currentFilter) {
        newQuery.filter = currentFilter;
        this.activeFilter = currentFilter;
      }

      this.$store.commit('ui/SET_LOADING', false);

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });
    },
    handleFilterClick(selectedFilter) {
      let newQuery = { ...this.$route.query };

      if (selectedFilter == 'all') {
        delete newQuery.filter;
        this.activeFilter = selectedFilter;
      } else {
        newQuery.filter = selectedFilter.url;
      }

      this.updateRoute(newQuery);

      if (selectedFilter != 'all') {
        this.activeFilter = selectedFilter.url;
      }
    },
    updateRoute(newQuery) {
      this.$store.commit('ui/SET_LOADING', false);

      this.$router
        .push({
          path: this.$route.path,
          query: newQuery,
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            // Captura cualquier excepción que no sea NavigationDuplicated
            throw err;
          }
        });

      this.$store.commit('theaters/INIT_STATE');
      this.fetchTheaters(newQuery);
      this.visibleTheaters = 21;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadTheaters();
    this.is_loading = false;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadQueryParams();
    });
  },
};
</script>
