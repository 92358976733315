<template>
  <div>
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">{{ translatedText('loading') }}</h1>
        </div>
      </template>
    </b-loading>
    <section v-if="blog && blog.post_video == 0 && blog.estatus == 1 && (blog.estados_id == selectedState || blog.estados_id == 33) && !is_loading" class="blog-page-detail">
      <div class="blog-detail-header">
        <div class="container">
          <div class="columns is-centered is-multiline">
            <div v-if="publicidad.length > 0" class="column is-6">
              <Advertising />
            </div>
            <div class="column is-12">
              <h2 class="title">{{ blog.titulo }}</h2>
              <h2 class="subtitle">{{ formatDate(blog.fecha) }}</h2>
            </div>
            <div class="column is-12">
              <div class="banner">
                <figure class="image">
                  <img :src="blog.portada ? blog.portada + '/1400/328' : 'https://voyalteatro.com/backend/api/img/render/9339'" />
                </figure>
              </div>
            </div>
          </div>
          <div class="blog-box">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="blog-content" v-html="blog.contenido"></div>
              </div>
            </div>
          </div>
          <div v-if="blog.video_1 || blog.video_2" class="blog-videos">
            <div class="columns is-multiline">
              <div class="column is-6">
                <div v-if="blog.video_1" class="blog-video-1">
                  <iframe :src="blog.video_1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
                </div>
              </div>
              <div class="column is-6">
                <div v-if="blog.video_2" class="blog-video-2">
                  <iframe :src="blog.video_2" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
                </div>
              </div>
            </div>
          </div>
          <div v-if="blog.autor" class="columns is-multiline">
            <div class="author-blog">
              <!-- Información del autor -->
              <div class="column is-12 is-paddingless">
                <h3>{{ translatedText('blog_authorship') }}</h3>
              </div>
              <div class="column is-12">
                <a :href="blog.autor_url" target="_blank" rel="noopener noreferrer">
                  <article class="media author-info">
                    <figure class="media-left">
                      <p class="image is-64x64">
                        <img class="is-rounded" :src="blog.autor_portada" alt="Foto del autor" />
                      </p>
                    </figure>
                    <div class="media-content">
                      <div class="content">
                        <p>
                          <strong>{{ blog.autor }}</strong>
                        </p>
                      </div>
                    </div>
                  </article>
                </a>
              </div>
            </div>
          </div>
          <div v-if="blog.related && blog.related.length > 0" class="list">
            <div class="columns is-multiline is-centered">
              <div class="column is-12">
                <h2 class="title">{{ translatedText('blog_more') }} {{ blog.categoria_nombre }}...</h2>
              </div>
              <div v-for="related in blog.related" :key="related.id" class="column is-one-fifth">
                <router-link
                  class="blog-list-card"
                  :to="{ name: 'blog-detail', params: { id: related.id } }"
                  :style="{ backgroundImage: `url(${related.portada || 'https://voyalteatro.mx/empty.png'})` }"
                >
                  <div class="card-blog-details">
                    <p class="card-blog-name clamp-3">{{ related.titulo }}</p>
                  </div>
                  <div class="card-image">
                    <img :src="'https://voyalteatro.mx/empty.png'" alt="" />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="google-adds">
        <GoogleAdds :key="$route.fullPath" />
      </section>
    </section>
    <NotFound
      v-if="!is_loading && blog && (blog.estatus == 0 || (blog.estatus == 1 && blog.estados_id != selectedState && blog.estados_id != 33))"
      :title="getNotFoundTitle()"
      :subtitle="getNotFoundSubtitle()"
      :url="'/'"
    />
    <NotFound v-if="!is_loading && !blog.titulo" :title="getNotFoundTitle()" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import NotFound from '../404.vue';
import Advertising from '../../../components/Common/advertising.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  name: 'Blog',
  components: {
    NotFound,
    Advertising,
    GoogleAdds,
  },
  data() {
    return {
      is_loading: true,
    };
  },
  computed: {
    ...mapState({
      blog: (state) => state.blogs.blog,
      selectedState: (state) => state.statesCatalog.selectedState,
      publicidad: (state) => state.dashboard.publicidad,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions('blogs', ['fetchBlog']),
    async loadBlog() {
      await this.fetchBlog({ blog_id: this.$route.params.id });
      this.is_loading = false;
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      moment.locale('es');
      let fechaFormateada = moment(date).format('DD [de] MMMM [de] YYYY'); // Utilizamos 'DD' en lugar de 'D'
      fechaFormateada = `${fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)}`;
      return fechaFormateada;
    },
    getNotFoundTitle() {
      return 'Publicación no encontrada';
    },
    getNotFoundSubtitle() {
      if (this.blog.estatus == 0) {
        return 'Lo siento, esta publicación ya no está disponible.';
      } else if (this.blog.estatus == 1 && this.blog.estados_id != this.selectedState && this.blog.estados_id != 33) {
        return `Lo siento, la publicación "${this.blog.titulo}" pertenece al estado de "${this.blog.estado}". Por favor, cambia al estado correspondiente para ver esta publicación.`;
      }
      return '';
    },
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      if (newId !== oldId) {
        this.is_loading = true;
        this.loadBlog();
        window.scrollTo(0, 0); // Enviar al usuario a la parte superior de la página cuando cambia la ruta
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadBlog();
    });
  },
};
</script>