<template>
  <div class="ads-component">
    <div class="columns is-centered">
      <div class="column is-6">
        <div class="ads-content">
          <h2 class="title">{{ translatedText('google_title') }}</h2>
          <ins
            class="adsbygoogle"
            style="display: inline-block; width: 100%; height: 100px"
            data-ad-client="ca-pub-4836867527790240"
            data-ad-slot="9518692755"
          ></ins>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadGoogleAds();
  },
  methods: {
    loadGoogleAds() {
      // Verifica si el script de adsbygoogle ya está presente
      if (!window.adsbygoogle) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.onload = () => this.loadAd();
        document.head.appendChild(script);
      } else {
        this.loadAd();
      }
    },

    loadAd() {
      setTimeout(() => {
        const adElements = document.querySelectorAll('.adsbygoogle');

        adElements.forEach((el) => {
          if (!el.hasAttribute('data-ad-status')) {
            try {
              (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (error) {
              console.error('Error al cargar el anuncio:', error);
            }
          }
        });
      }, 1000);
    },
  },
};
</script>

